import React, { Component } from "react";
import styled from "styled-components";
import {getListOfPost} from "../../api/PostAPI";

class MobileMiddleBanner extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined,
            // relatedData: [],
        }
    }
    componentDidMount() {
        getListOfPost({
            offset: 0,
            limit: 1,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfIdx: [73198, 73200, 73199].join(','),
            summary: false
        }, (res) => {
            var listOfPost = res.resultData;
            if (listOfPost.length!==1) return;

            let _post = listOfPost[0]
            let thumbnail = _post.meta.content.thumbnail
            let link = _post.meta.content.description

            this.setState({
                data: {
                    thumbnail: thumbnail,
                    link: link
                }
            })
        })
    }


    render() {
        return this.state.data === undefined ? <></>:
            <Container onClick={(e) => {
                window.location.href = this.state.data.link
            }}>
                <img src={this.state.data.thumbnail} />
            </Container>

    }

}


export default MobileMiddleBanner;

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &>img {
    width: 100%;
  }

  @media (min-width: 1048px){
    display: none;
  }

`;
