import React, { Component } from "react";
import PCHeaderSideBanner from "../../../awards/PCHeaderSideBanner";

class SideBanner extends Component {

  render() {
    return <PCHeaderSideBanner language={this.props.language}/>

  }

}


export default SideBanner;
