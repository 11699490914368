import React, { Component } from "react";
import { SEARCH_POP_UP } from "global/string";

import SearchPopUpView from "components/view/popup/app/searchPopUp"
import { getQueryParameter } from "utils/UriUtils";
import { getSearchArticle } from "api/NewsAPI";

class AppSeachPopUpVM extends Component {

    constructor() {
        super();
        this.state = {
            data: [],
            order: 0,
            isData: false,
            isArticle: false,
            isLoading: false
        }
    }

    ConvertSystemHtmlChartoSource(str) {
        str = str.replace(/&lt;/gi, '<');
        str = str.replace(/&gt;/gi, '>');
        str = str.replace(/&quot;/gi, '"');
        str = str.replace(/&#39;/gi, "'");
        str = str.replace(/<br \/>/gi, '\n');
        str = str.replace(/&nbsp;/gi, ' ');
        str = str.replace(/(<([^>]+)>)/gi, '');
        str = str.substring(0, 300);
        return str;
    }

    componentDidMount() {
        var header = 'all'
        if (this.props.langCode === 'es') {
            header = 'todos'
        }

        getSearchArticle({
            'header': header,
            'langCode': this.props.langCode,
            'keyword': this.props.keyword,
            'startPage':1,
            'order':0
        }, (json) => {

            var articleList = json.resultData.articleList;
            var articleData = [];

            if (articleList) {
                articleList.map((el, i) => {
                    articleData.push({
                        langCode: el.lang.toLowerCase(),
                        content: this.ConvertSystemHtmlChartoSource(el.content),
                        finalIdx: el.finalIdx,
                        thumbnail: el.thumbnail,
                        title: el.title
                    })
                    return true;
                })
            }

            this.setState({
                data: articleData,
                isData: true,
                isArticle: (articleData.length > 0 ? true : false)
            })
        })
    }

    onClickSearchBtn = (_data) => {
        this._repalceArticleList(_data);
    }

    onClickOrderBtn = (_data) => {
        this._repalceArticleList(_data);
    }

    _repalceArticleList(_data) {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            })

            getSearchArticle({
                'header': _data.header,
                'langCode': _data.langCode,
                'keyword': _data.keyword,
                'startPage':_data.startPage,
                'order':_data.order
            }, json => {
                var articleList = json.resultData.articleList;
                var articleData = [];

                if (articleList) {
                    articleList.map((el, i) => {
                        articleData.push({
                            langCode: el.lang.toLowerCase(),
                            content: this.ConvertSystemHtmlChartoSource(el.content),
                            finalIdx: el.finalIdx,
                            thumbnail: el.thumbnail,
                            title: el.title
                        })
                        return true;
                    })
                }

                this.setState({
                    keyword: _data.keyword,
                    order: _data.order,
                    data: articleData,
                    isData: true,
                    isArticle: (articleData.length > 0 ? true : false),
                    isLoading: false
                })
            })
        }
    }

    onClickArticle = (_data) => {
        var link = 'whosfanglobal://open?url=' + encodeURIComponent(window.location.origin + '/app/' + _data.langCode + '?fc=' + _data.finalIdx);
        window.location.href = link;
    }

    onClickClose = () => {
        this.props.onClickClose();
    }

    onScrollEvent = (_data) => {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true
            })

            getSearchArticle({
                'header': _data.header,
                'langCode': _data.langCode,
                'keyword': _data.keyword,
                'startPage':_data.startPage,
                'order':_data.order
            }, json => {
                if (json.resultData===undefined || json.resultData===null){
                    this.setState({
                        isLoading: false
                    })
                    return;
                }

                var articleList = json.resultData.articleList;
                var articleData = [];

                if (articleList){
                    articleList.map((el, i) => {
                        articleData.push({
                            langCode: el.lang.toLowerCase(),
                            content: this.ConvertSystemHtmlChartoSource(el.content),
                            finalIdx: el.finalIdx,
                            thumbnail: el.thumbnail,
                            title: el.title
                        })
                        return true;
                    })
                }

                var list = [...this.state.data];
                list = list.concat(articleData)

                this.setState({
                    data: list,
                    order: _data.order,
                    isData: true,
                    isLoading: false
                })
            })
        }
    }

    render() {
        return (
            <>
                {this.state.isData !== false ?
                    <SearchPopUpView
                        articleList={this.state.data}
                        keyword={this.props.keyword}
                        langCode={this.props.langCode}
                        text={SEARCH_POP_UP[this.props.langCode]}
                        order={this.state.order}
                        isArticle={this.state.isArticle}
                        onScrollCallBack={this.onScrollEvent}
                        onClickSearchCallBack={this.onClickSearchBtn}
                        onClickCallBack={this.onClickArticle}
                        onClickCloseCallBack={this.onClickClose} />
                    :
                    <></>}
            </>
        );
    }

}

export default AppSeachPopUpVM;
