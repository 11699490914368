import React, { Component } from "react";
import styled from "styled-components";
import {getListOfPost} from "../../api/PostAPI";

class PCHeaderSideBanner extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined,
            // relatedData: [],
        }
    }
    componentDidMount() {
        let idx = this.props.language === "KO"? 73198: this.props.language === "EN" ? 73200 : this.props.language === "ES" ? 73199 : 73200
        getListOfPost({
            offset: 0,
            limit: 1,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfIdx: `${idx}`,
            summary: false
        }, (res) => {
            var listOfPost = res.resultData;
            if (listOfPost.length!==1) return;

            let _post = listOfPost[0]
            let thumbnail = _post.meta.content.thumbnail
            let link = _post.meta.content.description

            this.setState({
                data: {
                    thumbnail: thumbnail,
                    link: link
                }
            })
        })
    }


    render() {
        return this.state.data === undefined? <Blank></Blank>:
            (<Container onClick={(e) => {
                window.location.href = this.state.data.link
            }}>
                <img src={this.state.data.thumbnail} />
            </Container>);


    }

}


export default PCHeaderSideBanner;

const Blank = styled.div`
  width: 300px;
  height: 100px
`;
const Container = styled.div`
  @media (min-width: 1048px){
    display: flex;
  }
  
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &>img {
    width: auto;
    height: 100px;
  }


`;
